<template>
  <button
    :class="{ 'button': useButtonClass }"
    @click="handleClick"
  >
    <slot>Button Text</slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    /**
     * buttonType is being
     * used for snowplow button
     * tracking below.
     * this is not a replacement
     * for the button type attr
     */
    buttonType: {
      type: String,
      default: '',
    },
    /**
     * useButtonClass can be set
     * to false in the rare
     * situation where you want
     * to use this component but
     * don't want to inherrit all
     * the styles that come with
     * the .button class
     */
    useButtonClass: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: this.buttonType,
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$emit('click', event);
    },
  },
};
</script>

<docs>
</docs>
